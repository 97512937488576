import axios from "axios";

const API_URL = process.env.GATSBY_NODE_API;

class PaymentService {
  createCharge({ paymentMethod, total }) {
    return axios
      .post(API_URL + "public/payments/stripe", {
        paymentMethod,
        total,
      })
      .then((response) => {
        return response.data;
      });
  }

  createDonateSession({ amount }) {
    return axios
      .post(API_URL + "public/payments/create-checout-session", {
        amount,
      })
      .then((response) => {
        return response.data;
      });
  }

  createAuthDonateSession({ amount }) {
    const { token } = JSON.parse(localStorage.getItem("user"));
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    return axios
      .post(
        API_URL + "public/auth/payments/create-checout-session",
        {
          amount,
        },
        config
      )
      .then((response) => {
        return response.data;
      });
  }
}
export default new PaymentService();

import React from "react";
import { useStripe } from "@stripe/react-stripe-js";
import PaymentService from "../services/payment.service";
import { loadStripe } from "@stripe/stripe-js";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY);

// const api_endpoint = process.env.GATSBY_NODE_API;

function DonateForm() {
  const stripe = useStripe();
  // const elements = useElements();

  // const handleSubmit = async (event) => {
  //   // Block native form submission.
  //   event.preventDefault();

  //   if (!stripe || !elements) {
  //     // Stripe.js has not loaded yet. Make sure to disable
  //     // form submission until Stripe.js has loaded.
  //     return;
  //   }

  //   // Get a reference to a mounted CardElement. Elements knows how
  //   // to find your CardElement because there can only ever be one of
  //   // each type of element.
  //   const cardElement = elements.getElement(CardElement);

  //   // Use your card Element with other Stripe.js APIs
  //   const { error, paymentMethod } = await stripe.createPaymentMethod({
  //     type: "card",
  //     card: cardElement,
  //   });

  //   if (error) {
  //     console.log("[error]", error);
  //   } else {
  //     console.log("[PaymentMethod]", paymentMethod);
  //     var total = 5;
  //     const PaymentCharge = await PaymentService.createCharge({
  //       paymentMethod,
  //       total,
  //     });
  //   }
  // };

  const handleClick = async (event) => {
    event.preventDefault()
    // Get Stripe.js instance
    const stripe = await stripePromise;
    var amount = 5;
    // Call your backend to create the Checkout Session
    const response = await PaymentService.createDonateSession({ amount });
    console.log(response)
    const {session} = response;

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  };

  return (
    <div> 
        {/* <CardElement hidePostalCode={true} /> */}
        <button onClick={handleClick} disabled={!stripe}>
          Pay
        </button> 
    </div>
  );
}

export default DonateForm;

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import React from "react";
import DonateForm from "../components/donate-form";
import Layout from "../components/layout";
import Seo from "../components/seo";
import HeaderAd from "../components/ads/header-ad";
import { AuthProvider } from "../context/auth-context";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY);

// markup
const DonatePage = () => {
  return (
    <AuthProvider>
      <Layout>
        <Seo title={`Online Hangman`} />
        <Elements stripe={stripePromise}>
          <div>
            <HeaderAd />
          </div>
          <div className={"container mx-auto px-4  py-6"}>
            <h1 className={"text-4xl font-bold mb-10"}>Donate</h1>
            <div className={"w-full p-4 lg:p-20  bg-white"}>
              <DonateForm />
            </div>
          </div>
        </Elements>
      </Layout>
    </AuthProvider>
  );
};

export default DonatePage;
